import { useMemo } from 'react'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import grey from '@mui/material/colors/grey'
import common from '@mui/material/colors/common'
import { deepmerge } from '@mui/utils'
import CssBaseline from '@mui/material/CssBaseline'

import { useAppState } from 'hooks'

const cache = createCache({
  key: 'za',
  prepend: true
})

// eslint-disable-next-line react/prop-types
export default function TopLayout({ children, theme }) {
  const { colorMode } = useAppState()

  const adjustedTheme = useMemo(() => {
    const base = {
      palette: {
        mode: colorMode,
        ...colorMode === 'dark' ? {
          background: {
            default: '#151921',
            paper: '#121417',
          }
        } : {
          background: {
            default: grey[50],
            paper: common.white,
          },
          text: {
            primary: common.black
          }
        }
      },
    }
    return createTheme(deepmerge(base, theme))
  }, [colorMode])

  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={adjustedTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </CacheProvider>
  )
}
