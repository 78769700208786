import { useCallback, useEffect, useLayoutEffect, useRef } from 'react'
import isFunction from 'lodash/isFunction'

import useRefSync from './useRefSync'

function useIsMounted() {
  const isMounted = useRef(false)
  useLayoutEffect(() => {
    isMounted.current = true
    return () => { isMounted.current = false }
  }, [])
  return isMounted.current
}

function useIsMountedRef() {
  const isMounted = useRef(false)
  useLayoutEffect(() => { isMounted.current = true }, [])
  return isMounted
}

function useEvent(handler) {
  const isMounted = useIsMountedRef()
  const handlerRef = useRefSync(handler)

  return useCallback((...args) => {
    if (!isMounted.current) {
      throw new Error('A useEvent callback cannot be called during the initial render.')
    }
    return handlerRef.current(...args)
  }, []); // eslint-disable-line
}


function useOnMount(handler, onUnmount) {
  useEffect(() => {
    if (!handler) return undefined

    const response = handler()

    return onUnmount || (isFunction(response) ? response : undefined)
  }, []); // eslint-disable-line

  return useIsMounted()
}

function useOnUpdate(handler, dependencies) {
  const isMounted = useRef(false)

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
      return undefined
    }

    const response = handler()

    return isFunction(response) ? response : undefined
  }, dependencies); // eslint-disable-line
}


function useOnUnmount(handler) {
  const handlerRef = useRefSync(handler)
  useEffect(() => () => { handlerRef.current() }, [])
}

export {
  useIsMounted,
  useIsMountedRef,
  useEvent,
  useOnMount,
  useOnUpdate,
  useOnUnmount,
}
